import React from "react";
import Helmet from "react-helmet";
import Layout from "src/layouts/LayoutWhiteHeader";
import config from "data/SiteConfig";

import ContactMeForm from "src/components/generic/ContactMeForm/ContactMeForm";

const ContactPage = () => (
	<Layout>
		<main>
			<Helmet title={`Contact Us | ${config.siteTitle}`} />
		</main>
		<div className="container m-t-xl m-b-xl">
			<div className="columns">
				<div className="column is-half">
					<h2>Contact us</h2>
					<p>
						If you&#39;re interested in a demo please fill in the
						form and we&apos;ll get in touch.
					</p>
					<ContactMeForm formName="contact-us" />
				</div>
			</div>
		</div>
	</Layout>
);
export default ContactPage;
